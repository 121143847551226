import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURI } from "../config";
import IsTermsPopUp from "../context/IsTermsPopUp";
import TermsChecked from "../context/TermsChecked";
import { TermsPopUp } from "../popups/TermsPopUp";

function Authentication(props) {
  const gameId = props.match.params.gameID;
  const sceneId = props.match.params.sceneId;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useContext(TermsChecked);
  const [isPopupOpen, setIsPopupOpen] = useContext(IsTermsPopUp);
  let currentUrl = window.location.href.split(":").pop();
  console.log(currentUrl);

  const handleSave = (event) => {
    event.preventDefault();
    if (isTermsChecked) {
      const data = {
        email: email.toLowerCase(),
        password: password,
      };
      axios
        .post(`${APIURI}/users/login`, data)
        .then((res) => {
          const token = res.data.accessToken;
          console.log(token);
          localStorage.setItem("accesstoken", token);
          const prevURL = localStorage.getItem("redirect_url");
          // if pod is included in url
          if (window.location.href.includes("pod:")) {
            window.location.href = `/pods:${
              window.location.href.split("pod:").pop().split("/")[0]
            }`;
          } else if (gameId === undefined) {
            if (prevURL) {
              window.location.href = prevURL;
            } else {
              // setErrorMessage("Scan QR code before logging in to begin play.");
              window.location.href='/profile'
            }
            // history.push(prevURL);
          } else {
            if (prevURL) {
              window.location.href = prevURL;
            } else if (currentUrl.split("/").pop() === "0") {
              window.location.href = `/location:${currentUrl.split("/")[0]}`;
            } else if (currentUrl) {
              window.location.href = `/solution:${currentUrl}`;
            } else {
              // setErrorMessage("Scan QR code before logging in to begin play.");
              window.location.href='/profile'
            }
            // history.push(prevURL);
          }
        })
        .catch(function (error) {
          if (error.response) {
            setErrorMessage(error.response.data.message);
          }
        });
    } else {
      setErrorMessage("Please accept Terms & Conditions.");
      console.log("else");
      // setIsPopupOpen(true)
    }
  };

  return (
    <div className="Auth-login">
      <div className="Auth-header">
        <div className="container">
          <img
            src="/assets/Escapely@3x.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>
        <p>Sign in to continue</p>
      </div>
      <div className="Auth-container">
        <input
          type="text"
          className="login-input login-email"
          placeholder="ENTER EMAIL"
          autocomplete="off"
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* <TextField
          type="email"
          id="outlined-basic"
          label="EMAIL"
          variant="standard"
          required="true"
          onChange={(e) => setEmail(e.target.value)}
        />{" "} */}
        <label>EMAIL</label>
        <br />
        <div className="password-txt">
          <input
            type="password"
            placeholder="ENTER PASSWORD"
            className="login-input password-circle"
            autocomplete="off"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>PASSWORD</label>
        </div>
        {/* <TextField
          type="password"
          id="outlined-basic"
          label="PASSWORD"
          variant="standard"
          required="true"
          onChange={(e) => setPassword(e.target.value)}
        />{" "} */}
        <br />
        <p className="wrong-ans">{errorMessage}</p>
        {isPopupOpen ? <TermsPopUp type="signin" /> : ""}
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            style={{ marginTop: "1em", marginRight: "5px" }}
            className="terms-condition-label"
            control={
              <Checkbox
                className="terms-checkbox"
                // onChange={(e) => {
                //   setIsTermsChecked(e.target.checked);
                //   console.log(e.target.checked);
                // }}
                onClick={() => {
                  setIsTermsChecked(!isTermsChecked);
                }}
                name="checkedB"
                checked={isTermsChecked}
                color="dark"
              />
            }
            label=<p className="terms-condition">I accept the</p>
          />
          <span
            className="terms-condition"
            onClick={() => {
              setIsPopupOpen(true);
            }}
            style={{ color: "#000", textDecoration: "underline" }}
          >
            Terms & Conditions
          </span>
        </div>
        <Button className="signin" variant="contained" onClick={handleSave}>
          SIGN IN
        </Button>
      </div>
      <div className="forgot-pass">
        <div style={{ width: "48%", borderRight: "1.8px solid #000" }}>
          {gameId === undefined ? (
            <a href="/signup">Create Account</a>
          ) : (
            <a href={`/signup:${gameId}/${sceneId}`}>Create Account</a>
          )}
        </div>
        <div style={{ width: "51%" }}>
          {gameId === undefined ? (
            <a href="/forgotpassword">forgot password</a>
          ) : (
            <a href={`/forgotpassword:${gameId}/${sceneId}`}>forgot password</a>
          )}
        </div>
      </div>
      <div className="Auth-footer">
        {/* {gameId === undefined ? (
          <span>
            <span
              style={{ color: "#000", opacity: "0.5", marginRight: "0.5em" }}
            >
              New here?{" "}
            </span>
            <a className="cra" href="/signup">
              Create Account
            </a>
          </span>
        ) : (
          <span>
            <span
              style={{ color: "#000", opacity: "0.5", marginRight: "0.5em" }}
            >
              New here? {"   "}
            </span>
            <a className="cra" href={`/signup:${gameId}/${sceneId}`}>
              Create Account
            </a>
          </span>
        )} */}
        {/* <span>New here? <a href="/signup">Create Account</a></span> */}
        <Button
          className="shop--button"
          onClick={() => {
            window.open("https://escapely.com/lp-adventure-box/", "_blank");
          }}
        >
          SHOP
        </Button>
      </div>{" "}
    </div>
  );
}
export default withRouter(Authentication);
